import React, { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import useAuth from './hooks/useAuth';

// Preloader
const Preloader = React.lazy(() => import("./components/layouts/Preloader"));

// Home Pages
const Home = React.lazy(() => import("./components/pages/Home"));
// Pages
const About = React.lazy(() => import("./components/pages/About"));
const Login = React.lazy(() => import("./components/pages/Login"));

// Listings
const Listing = React.lazy(() => import("./components/pages/Listing"));
const Listingdetails = React.lazy(() => import("./components/pages/Listingdetails"));
const Submitlisting = React.lazy(() => import("./components/pages/Submitlisting"));

export function Router() {
    const { isAuthenticated } = useAuth();
    return (
      <BrowserRouter>
        <Suspense fallback={<div></div>}>
          <Preloader />
          <Routes>
            <Route path="/" element={<Home />} />
  
            <Route path="/sobre-nos" element={<About />} />
            <Route path="/login" element={<Login />} />
  
            {/* Listings */}
            <Route path="/imovel/:id/:slug" element={<Listingdetails />} />
            <Route path="/imoveis" element={<Listing />} />
            <Route path="/:specifically/:type" element={<Listing />} />
            {isAuthenticated && <Route path="/anunciar-imovel" element={<Submitlisting />} />}
  
            <Route path="*" element={<Home />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    );
}